import { graphql } from 'gatsby'
import React from 'react'
import ColumnBackAndForth from '../../../components/atoms/columnBackAndForth'
import SEO from '../../../components/atoms/seo'
import { ITopicPath } from '../../../components/atoms/topicPath'
import ColumnAuthor002 from '../../../components/molecules/columnAuthor002'
import ColumnDetailInner0038 from '../../../components/molecules/columnDetailInner0038'
import ColumnRelationCards0038 from '../../../components/molecules/columnRelationCards0038'
import UsefulFunctionLayout015 from '../../../components/molecules/usefulFunctionLayout015'
import ColumnDetailsBody from '../../../components/organisms/columnDetailsBody'
import ColumnLayout from '../../../components/organisms/columnLayout'
import useRouteParam from '../../../hooks/useRouteParam'

/**
 * コラム記事詳細画面
 * @constructor
 */
const C0038 = () => {
  const paths: ITopicPath[] = [
    { title: 'キンクラコラムTOP', link: useRouteParam('/column') },
    { title: 'コラム記事一覧', link: useRouteParam('/column/list') },
    {
      title: '育児休暇とは何？育児休業との違いや気になる助成金の活用法を解説',
    },
  ]

  return (
    <ColumnLayout paths={paths}>
      <SEO
        title="育児休暇とは？育児休業との違いや気になる助成金の紹介"
        description="育児休暇と育児休業は似ているため混同しやすいですが、大きな違いは、対象となる年齢と法律の適応の有無です。育児休暇の導入は、内容をルール化する必要があります。"
        ogUrl="https://kintaicloud.jp/column/details/C0038/"
        ogType="article"
        ogTitle="育児休暇とは？育児休業との違いや気になる助成金の紹介"
        ogDescription="育児休暇と育児休業は似ているため混同しやすいですが、大きな違いは、対象となる年齢と法律の適応の有無です。育児休暇の導入は、内容をルール化する必要があります。"
        ogSiteName="キンクラ - 客先常駐特化型のクラウド勤怠管理システム"
        ogImage="https://www.kintaicloud.jp/images/column/details/c0038.jpg"
      />
      <ColumnDetailsBody>
        {/** 記事本体 */}
        <ColumnDetailInner0038 />
        {/** オーサ情報 */}
        <ColumnAuthor002 />
        {/** お役立ち機能 */}
        <UsefulFunctionLayout015 />
        {/** 前後記事への遷移 */}
        <ColumnBackAndForth />
        {/** 関連記事 */}
        <ColumnRelationCards0038 />
      </ColumnDetailsBody>
    </ColumnLayout>
  )
}

export default C0038

export const pageQuery = graphql`
  query C0038 {
    site {
      siteMetadata {
        title
      }
    }
  }
`

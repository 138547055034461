import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import ColumnH1 from '../atoms/columnH1'
import Introduction003 from './Introduction003'

const Wrapper = styled.article`
  position: relative;
  margin-bottom: 30px;

  .p-txt-date {
    margin-top: -10px;
    color: #ababab;
  }

  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 16px;
    color: #505050;
    line-height: 1.6em;

    h1 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #f5f5f5;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-bottom: 30px;
      margin-top: 10px;

      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #77d7c9;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }

    h2 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #ffffff;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-top: 10px;
      margin-bottom: 30px;

      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #d8d8d8;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }

    h3 {
      color: #414141;
      font-size: 16px;
      line-height: 1.4em;
      background: #ffffff;
      width: auto;
      padding: 10px 0 8px;
      margin-top: -10px;
      margin-bottom: 20px;
      border-top: solid 2px #e5e5e5;
      border-bottom: solid 2px #e5e5e5;
    }

    p {
      padding-bottom: 30px;
      line-height: 2;
      position: relative;

      span {
        background: linear-gradient(transparent 60%, #ff6 60%);
        font-weight: 600;
      }

      .p-list-link {
        position: absolute;
        top: 6px;
        right: 2px;

        &:before {
          display: block;
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          top: 7px;
          left: -14px;
          border-left: 8px solid #3ec7b3;
          border-top: 4px solid transparent;
          border-bottom: 4px solid transparent;
        }
      }

      &.txt-color-red {
        color: #ff6969;
      }
    }

    .link-div {
      width: 100%;
      display: flex;
      justify-content: left;
      padding-bottom: 30px;

      > div {
        width: 50px;
      }

      .link-p {
        width: calc(100% - 50px);

        .p-list-link {
          position: absolute;
          top: -3px;
          left: 30px;

          &:before {
            display: block;
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: 11px;
            left: -14px;
            border-left: 8px solid #3ec7b3;
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
          }
        }
      }
    }

    img {
      width: 100%;
      height: auto;
      padding-bottom: 30px;
    }
  }

  .toc {
    border: 1px solid #ababab;
    padding: 20px 40px;
    margin: 0 auto 30px;

    ol {
      padding-left: 30px;
    }

    p {
      text-align: center;
      padding-bottom: 10px;
    }

    a {
      text-decoration: underline;
    }

    li {
      list-style-type: decimal;
      display: list-item;
    }

    ul {
      padding-left: 30px;

      li {
        list-style-type: circle;
      }
    }
  }

  ${BreakPoints.large} {
  }
`

/**
 * コラム記事詳細：記事本体
 * @constructor
 */
const ColumnDetailInner0038 = () => (
  <Wrapper>
    <ColumnH1 label="育児休暇とは？育児休業との違いや気になる助成金の紹介" />
    {/** TODO 川上申し送り事項：▼投稿日をここに表示 */}
    <p className="p-txt-date">2022.10.14</p>
    <section>
      <img src="/images/column/details/c0038.jpg" alt="キンクラコラム" />
      {/** TODO 川上申し送り事項：▼記事投稿（？）で作成された内容がここに入る予定 */}
      <p>
        仕事とプライベートの両立による充実した健康的な生活を推奨する「ワーク・ライフ・バランス」が打ち出される中で新設された
        <span>「育児休暇」</span>
        制度をご存知でしょうか？名称から育児休業と間違えられることが多い制度ですが、実は助成金の申請にも関係する企業にとって非常に重要な制度となります。
        <br />
        今回は、育児休暇の概要や育児休業との違いを、制度導入に関する助成金の情報も含めて順に解説していきましょう。
      </p>
      <div className="toc">
        <p>目次</p>
        <ol>
          <li>
            <a href="#toc1">「育児休暇」とは</a>
          </li>
          <li>
            <a href="#toc2">育児休業との違いは</a>
          </li>
          <li>
            <a href="#toc3">育児休暇の設定方法</a>
            <ul>
              <li>
                <a href="#toc3-1">①対象となる労働者の範囲</a>
              </li>
              <li>
                <a href="#toc3-2">②休暇利用の目的</a>
              </li>
              <li>
                <a href="#toc3-3">③休暇付与日</a>
              </li>
              <li>
                <a href="#toc3-4">④取得方法</a>
              </li>
              <li>
                <a href="#toc3-5">⑤休暇中の賃金の取扱い</a>
              </li>
            </ul>
          </li>
          <li>
            <a href="#toc4">両立支援等助成金の活用</a>
            <ul>
              <li>
                <a href="#toc4-1">(1)支給要件</a>
              </li>
              <li>
                <a href="#toc4-2">(2)支給金額</a>
              </li>
              <li>
                <a href="#toc4-3">(3)申請時の注意ポイント</a>
              </li>
            </ul>
          </li>
          <li>
            <a href="#toc5">まとめ</a>
          </li>
        </ol>
      </div>
      <div id="toc1">
        <h1>「育児休暇」とは</h1>
      </div>
      <p>
        育児休暇とは、<span>出産前6週間から子が小学校入学前の期間</span>
        までにおいて、子を養育するために利用することができる休暇で、平成29年度の育児・介護休業法の改正時に新設された制度です。
        <br />
        法律上では、<span>「育児に関する目的で利用できる休暇制度」</span>
        、通称「育児目的休暇」と呼ばれますが、会社の状況に応じて「ファミリー休暇」など、休暇名を個別で設定することも可能です。
        <br />
        休暇の目的としては、たとえば配偶者出産時の付き添いや、入園式や運動会、卒園式などの行事出席が想定されていますが、その他会社の実情に沿った形で休暇目的の内容を設定することができます。
      </p>
      <div id="toc2">
        <h1>育児休業との違いは</h1>
      </div>
      <p>
        育児休業と育児休暇はどちらも子供の育児・養育のための休みであり、名称も非常に似かよっているため、混同するケースが非常に多くみられます。
        <br />
        しかし、その内容は大きく異なる点に注意が必要です。
        <br />
        <br />
        まず、<span>育児休業は法律によって取得が義務</span>
        づけられており、すべての企業が一律で付与しなければならない公的休業制度ですが、育児休暇は企業に対する
        <span>「努力義務」</span>
        とされており、内容の詳細は各企業の裁量にゆだねられている任意制度である点が大きく異なります。
        <br />
        公的休業である育児休業を取得した場合は、休業中の生活保障として雇用保険制度による
        <span>育児休業給付金</span>
        が休業者へ支払われますが、育児休暇の場合は休業中の賃金支払い有無は会社で定められた内容によるため、中には無給の休暇となるケースもみられます。
        <br />
        <br />
        また、育児休業の対象となるのは、
        <span>原則として1歳未満（最長2歳未満）の子供を養育する労働者</span>
        であることに対し、育児休暇の対象となるのは小学校へ就学する前までの子を養育する労働者である点も異なります。
      </p>
      <Introduction003 />
      <div id="toc3">
        <h1>育児休暇の設定方法</h1>
      </div>
      <p>
        前述の通り、育児休暇の詳細は会社の裁量にゆだねられています。したがって、育児休暇制度を社内で導入するためには、就業規則などで
        <span>内容をルール化</span>する必要があります。
        <br />
        後に述べる助成金制度の利用を検討する企業の場合は、目安となる設定内容をこれから記載しますので、参考にして下さい。
      </p>
      <div id="toc3-1">
        <h2>①対象となる労働者の範囲</h2>
      </div>
      <p>
        「子が6週間（双子以上の妊娠時は14週間）以内に出生予定の者、または小学校就学の始期に達するまでの子を養育する従業員」など
        <br />
        <br />
      </p>
      <div id="toc3-2">
        <h2>②休暇利用の目的</h2>
      </div>
      <p>「配偶者の出産支援」、「育児」、「各種行事出席」など</p>
      <div id="toc3-3">
        <h2>③休暇付与日</h2>
      </div>
      <p>
        「1年度あたり中小企業の場合は5日、その他規模企業の場合は8日以上」が原則
        <br />
        （時間単位、半日単位等での分割取得も可能ですが、その旨を記載する必要があります）
      </p>
      <div id="toc3-4">
        <h2>④取得方法</h2>
      </div>
      <p>「事前に所定様式の書類などにより申し出る」など</p>
      <div id="toc3-5">
        <h2>⑤休暇中の賃金の取扱い</h2>
      </div>
      <p>有給、無給いずれかを設定することが可能</p>
      <div id="toc4">
        <h1>両立支援等助成金の活用</h1>
      </div>
      <p>
        両立支援等助成金は、仕事と家庭を両立する労働者の支援や、女性の活躍推進を目的とした取り組みを行う企業を支援する助成制度で、一定要件を満たすことで一定額の助成金を受け取ることができます。
        <br />
        <br />
        育児休暇を新たに導入した企業は、この助成制度のうち、「出生時両立支援コース（子育てパパ支援助成金）」を活用することができる可能性があります。
        <br />
        <br />
        このコースは<span>男性労働者による育児休暇・育児休暇の取得を促進</span>
        させるねらいから定められている制度で、名称の通り支給対象として設定されているのは「男性」労働者となる点に注意が必要です。
      </p>
      <div id="toc4-1">
        <h2>(1)支給要件</h2>
      </div>
      <p>
        両立支援等助成金の対象となるためには、次の要件すべてを満たす必要があります。
        <br />
        ①男性労働者による育児休暇制度の新設
        <br />
        ②男性労働者が育児休暇を取得しやすい環境を整備していること
        <br />
        ③男性労働者が、中小企業の場合は5日、その他企業の場合は8日以上の育児休暇を取得すること
        <br />
        ④育児休暇制度の詳細を就業規則などで定めていること
        <br />
        ⑤一般事業主行動計画を策定・届け出ていること
        <br />
        ⑥助成金の支給申請日までに対象となる男性労働者が雇用保険に加入していること
      </p>
      <div id="toc4-2">
        <h2>(2)支給金額</h2>
      </div>
      <p>
        育児休暇を導入・利用させた中小企業の場合は28.5万円、その他企業の場合は14.25万円が原則として支給され、生産性要件を満たした場合は一定額が上乗せされます。
      </p>
      <div id="toc4-3">
        <h2>(3)申請時の注意ポイント</h2>
      </div>
      <p>
        育児休暇は、育児休業をはじめ、子の看護休暇や年次有給休暇とは異なる制度として新設する必要があるため注意しましょう。なお、平成30年4月よりも前にすでに育児休暇制度を導入している企業は助成金の対象外となります。
        <br />
        また、支給要件として定められている「育児休暇を取得しやすい環境の整備」には、たとえば男性労働者の育児休業取得にまつわる社内研修の実施や、リーフレットによる詳細内容説明などの活動が挙げられます。
      </p>
      <div id="toc5">
        <h1>まとめ</h1>
      </div>
      <p>
        育児休暇の導入時に活用することができる、男性向けの育児休業制度促進にまつわる資料は、厚生労働省のホームページでも提供されていますので、活用する方法も有効です。
        <br />
        特に小さい子供を育てながら働く労働者は、男女問わず苦労が絶えないものです。
        <br />
        <br />
        性別を超えた休暇制度を整備することで、労働者の仕事に対するモチベーションアップを図る効果もありますので、まずは社内体制を見直した上で、会社の実態に沿った形で導入を検討してみてはいかがでしょうか。
      </p>
    </section>
  </Wrapper>
)

export default ColumnDetailInner0038
